<template>
  <article class="container-fluid pb-10">
    <h1 class="main-title mb-8">{{ $t("mainNav.productionDataExport") }}</h1>
    <production-data-export-menu v-if="!isPresenter" />
  </article>
</template>

<script>
import ProductionDataExportMenu from "@/components/productiondataexport/ProductionDataExportMenu.vue";
import { mapGetters } from "vuex";

export default {
  name: "ProductionDataExportView",
  components: {
    ProductionDataExportMenu,
  },
  computed: {
    ...mapGetters("user", ["isPresenter"]),
  },
};
</script>

<style lang="scss" scoped />
