import axios from "axios";
import { getTokenRedirect } from "@/authentication/authRedirect";
import { tokenRequest } from "@/authentication/authConfig";

class ProductionDataExportService {
  fetchProductionData(payload, language) {
    const http = axios.create({
      baseURL: process.env.VUE_APP_DASHBOARD_API_BASE_URL,
      responseType: "blob",
    });
    http.interceptors.request.use(async (req) => {
      const token = await getTokenRedirect(tokenRequest);
      req.headers.Authorization = "Bearer " + token;
      if (req.data) req.headers["Content-Type"] = "application/json";
      return req;
    });

    const factoryId = encodeURIComponent(payload.factory_id);
    const startDateParam = encodeURIComponent(payload.start_time);
    const endDateParam = encodeURIComponent(payload.end_time);
    const productionUnitIds = encodeURIComponent(payload.production_unit_ids.join(","));
    const queryParams = `start_time=${startDateParam}&end_time=${endDateParam}&production_unit_ids=${productionUnitIds}&lang=${language}`;
    return http.get(`/v2/factories/${factoryId}/export?${queryParams}`);
  }
}

export default new ProductionDataExportService();
